import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { createSelfie } from 'src/api/services/selfie.services';
import * as Yup from 'yup';
import { RHFUploadVideo } from 'src/components/hook-form/rhf-upload-video';
import { useCallback } from 'react';

// Validation Schema
const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  mobileNo: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter a valid Mobile Number.')
    .nullable(),
  schoolName: Yup.string().required('School Name is required'),
  standard: Yup.string().required('Standard is required'),
  videoUpload: Yup.mixed()
    .required('Video Upload is required')
    .test('fileSize', 'Video size must not exceed 15 MB', (value) => {
      if (!value) return false;
      return value.size <= 15 * 1024 * 1024; // 15MB in bytes
    })
    .test('fileType', 'Please upload a valid video file', (value) => {
      if (!value) return false;
      return value.type.startsWith('video/');
    }),
});

// Default Values
const defaultValues = {
  name: '',
  schoolName: '',
  standard: '',
  mobileNo: '',
  videoUpload: '',
};

export default function SelfieRegistrationForm({ hideCancel = false }) {
  const navigate = useNavigate();
  const router = useRouter();
  const location = useLocation();
  const { t } = useLocales();

  // Initialize useForm here instead of using useFormContext
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;
  const values = watch();

  const uploadFile = async (file) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}api/upload`,
          formData
        );
        return data;
      }
      return null;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await uploadFile(data?.videoUpload);
      const payload = {
        name: data.name,
        standard: data.standard,
        schoolName: data.schoolName,
        videoUpload: response?.data,
        mobileNo: data.mobileNo,
      };

      await createSelfie(payload);

      if (location.pathname !== paths.hssfPaths.selfie.management.root) {
        router.push(paths.hssfPaths.selfie.management.root);
      }
      enqueueSnackbar('Registration Successfully');
      reset();
    } catch (error) {
      console.error('Error: ', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      }
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        // Check file size
        if (file.size > 25 * 1024 * 1024) {
          enqueueSnackbar('Video size must not exceed 25 MB', { variant: 'error' });
          return;
        }

        // Check file type
        if (!file.type.startsWith('video/')) {
          enqueueSnackbar('Please upload a valid video file', { variant: 'error' });
          return;
        }

        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

        setValue('videoUpload', newFile, { shouldValidate: true });
      }
    },
    [setValue, enqueueSnackbar]
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={2.5}>
        {/* Name Field */}
        <Grid xs={12}>
          <RHFTextField name="name" label="Name *" fullWidth />
        </Grid>

        {/* School Name Field */}
        <Grid xs={12}>
          <RHFTextField name="schoolName" label="School Name *" fullWidth />
        </Grid>

        {/* Standard Field */}
        <Grid xs={12}>
          <RHFTextField name="standard" label="Standard *" fullWidth />
        </Grid>

        {/* Mobile No Field */}
        <Grid xs={12}>
          <RHFTextField
            name="mobileNo"
            label="Mobile No.*"
            type="number"
            inputProps={{ maxLength: 10 }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 10);
            }}
            fullWidth
          />
        </Grid>

        {/* Video Upload Field */}
        <Grid xs={12}>
          <RHFUploadVideo
              multiple
              files={[values.videoUpload]}
              name="videoUpload"
              maxSize={25}
              helperText={
              <>
                Maximum file size: 25 MB
              </>
              }
              onDrop={handleDrop}
              // onRemove={handleRemoveVideoFile}
              // onRemoveAll={handleRemoveAllVideoFiles}
            />
          
        </Grid>

        {/* Submit and Cancel Buttons */}
        <Grid xs={12} textAlign="end" display="flex" justifyContent="flex-end" gap={2}>
          <LoadingButton
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('register')}
          </LoadingButton>

          {/* Cancel Button */}
          {!hideCancel && (
            <Button
              sx={{ maxWidth: 'fit-content' }}
              color="inherit"
              size="large"
              variant="outlined"
              onClick={() => {
                methods.reset();
                navigate(paths.hssfPaths.selfie.management.root);
              }}
            >
              {t('cancel')}
            </Button>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
}

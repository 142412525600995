import axios from 'axios';
import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

export const getSelfieList = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/selfie`;
  const { data } = await axiosInstance.get(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return data;
};

export const updateSelfieToGoogleSheet = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/selfie/google-sheet`;
  try {
    const { data } = await axiosInstance.get(url, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return data;
  } catch (error) {
    if (error.response?.status === 403) {
      return null;
    }
    throw error;
  }
};

export const createSelfie = async (payload) => {
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_BACKEND_URL}api/selfie`,
    payload,
    {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    }
  );
  return data;
};

export const updateSelfie = async (id, payload) => {
  const { data } = await axiosInstance.put(
    `${process.env.REACT_APP_BACKEND_URL}api/selfie/${id}`,
    payload,
    {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    }
  );
  return data;
};

export const deleteSelfie = async (id) => {
  const { data } = await axiosInstance.delete(
    `${process.env.REACT_APP_BACKEND_URL}api/selfie/${id}`,
    {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    }
  );
  return data;
};

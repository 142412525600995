import React, { useState } from 'react';
import {
  Card,
  Typography,
  Grid,
  Divider,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import Image from 'src/components/image';
import {
  convertInterestsToLabels,
  currentUser,
  getFairYear,
  interests_options,
} from 'src/utils/utils';
import { LoadingButton } from '@mui/lab';
import ProfileEdit from './profile-edit';

export default function ProfileView() {
  const userData = currentUser();

  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);

  const renderUserDetails = (
    <Stack spacing={2} sx={{ p: 0, pt: 0, typography: 'body2' }}>
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          <Image
            alt="user image"
            src={userData?.userImage}
            ratio="1/1"
            sx={{ borderRadius: 2, cursor: 'pointer' }}
          />
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />

      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.name')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.firstName && userData?.lastName
            ? `${userData.firstName} ${userData.lastName}`
            : '--'}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('mobile_number')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.mobileNo}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.whatsapp_number')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.whatsappNo}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.email_id')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.email}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.address')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.address}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.interest')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.interest
            ? convertInterestsToLabels({
                string: userData?.interest?.join(','),
                options: interests_options,
              })
            : '--'}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.working_time_hrs')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.workingPreference || '--'} / {userData?.workingHrs || '--'}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.profession')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.profession || '--'}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.responsible_for_fair')} - {getFairYear()}
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.responsibleForFair ? 'Yes' : 'No'}
        </Grid>
      </Grid>
    </Stack>
  );

  const renderUserDetails2 = (
    <Stack spacing={2} sx={{ p: 0, pt: 0, typography: 'body2' }}>
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.pin_code')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.pinCode || '--'}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('district')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.districtName || '--'}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('taluka')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.talukaName || '--'}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.any_other_responsibility')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.anyOtherResponsibility || '--'}
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid item xs={12} md={4}>
          {t('user_registration_page.input_field.aadhar_number')} :
        </Grid>
        <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
          {userData?.aadharNumber || '--'}
        </Grid>
      </Grid>
      {userData?.aadharImage && (
        <>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid item xs={12} md={8} sx={{ color: 'text.secondary' }}>
              <LoadingButton
                sx={{ maxWidth: 'fit-content', mr: 2 }}
                color="inherit"
                size="large"
                variant="contained"
                onClick={() => {
                  if (userData?.aadharImage) {
                    window.open(userData?.aadharImage, '_blank');
                  }
                }}
              >
                View Aadhar
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {t('user_profile')}
      </Typography>

      <Stack direction="row" spacing={2} sx={{ mb: 3, justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={() => setIsEditMode(!isEditMode)}>
          {isEditMode ? t('view_profile') : t('edit_profile')}
        </Button>
      </Stack>

      {isEditMode ? (
        <ProfileEdit userData={userData} setIsEditMode={setIsEditMode} />
      ) : (
        // <div>ProfileEdit</div>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, height: '100%' }}>{renderUserDetails}</Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, height: '100%' }}>{renderUserDetails2}</Card>
          </Grid>
        </Grid>
      )}
    </Card>
  );
}

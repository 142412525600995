import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import FormProvider from 'src/components/hook-form';
import { RHFMultiCustomCheckbox } from 'src/components/hook-form/rhf-custom-checkbox';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import * as Yup from 'yup';
import { createSankalp } from 'src/api/services/feedback.services';
import { useEffect } from 'react';

const RegisterSchema = Yup.object().shape({
  sankalpSelections: Yup.array().min(1, 'Please select at least one option'),
});

const defaultValues = {
  sankalpSelections: [],
};

const sankalpOptions = [
  { value: '1', label: 'હુંવૃક્ષોને વનના પ્રતિક માનીને દર વર્ષે એક વૃક્ષનું રોપણ કરીશ તથા તેની વર્ષભરની ઉછેરની વ્યવસ્થા કરીશ.' },
  { value: '2', label: 'હું ગાયને સમગ્ર જીવસૃષ્ટિના પ્રતીક તરીકે માનીને આદર કરીશ તથા ગાય ઉછેર તેમ જ ગાય પાસેથી પ્રાપ્ત ઉત્પાદનોનો વપરાશ કરીશ.' },
  { value: '3', label: 'હું નદીઓને પ્રકૃતિના પ્રતિક માનીને તેનો જીવનભર આદર કરીશ તથા નદીને સ્વચ્છ રાખવા અંગેના કાર્યોમાં જોડાઇશ.' },
  { value: '4', label: 'હું ધરતીમાતાને પર્યાવરણના પ્રતિક તરીકે માનીને આદર કરીશ તેની સ્વચ્છતા જાળવવા માટે ક્યારેય પણ કચરો અયોગ્ય જગ્યા પર નાંખીશ નહીં.' },
  { value: '5', label: 'હું મારા માતા-પિતા તથા વડીલોને માનવીય મૂલ્યોના પ્રતીક તરીકે માનીને તેમનો આદર કરીશ' },
  { value: '6', label: 'હુંમારા શિક્ષકોને જ્ઞાન સંવર્ધનના પ્રતિક માનીને તેમનો આદર કરીશ.' },
  { value: '7', label: 'હું નારીને માતૃત્વના પ્રતીક તરીકે માનીને તેમનો આદર કરીશ.' },
  { value: '8', label: 'હું યુદ્ધનાયકોને ભારતમાતાના પ્રતિક માનીને તેમનો આદર કરીશ.' },
  { value: '9', label: 'હું તુલસીના છોડને ઘરમાં ઉછેરીશ, રોજ પાણી પીવડાવીશ.' },
  { value: '10', label: 'હું પક્ષીઓ માટે પીવાના પાણી તથા અનાજના દાણાની વ્યવસ્થા મારા ઘરમાં રાખીશ.' },
  { value: '11', label: 'હું મારા કુટુંબના સભ્યો સાથે નિયમિત એક વખતનું ભોજન સૌની સાથે બેસીને લઇશ.' },
  { value: '12', label: 'હું ગીતા, મહાભારત, રામાયણ, ભાગવત, ઉપનિષદ તેમજ હિન્દુ સંસ્કૃતિના વિશાળ સાંસ્કૃતિક વારસા સમાન ગ્રંથોનો નિયમિત સમય આપીને અભ્યાસ કરીશ.' },
  { value: '13', label: 'હું જાહેર ટ્રાફ્કિ વ્યવસ્થા સુધારવા માટે વાહન વ્યવહારના નિયમોનું સ્વયં સંપૂર્ણ પાલન કરીશ.' },
  { value: '14', label: 'હુંદરરોજના એક કલાક દેશપ્રેમ-સંસ્કૃતિ સંવર્ધનના કાર્યો કરવામાં આપીશ.' },
  { value: '15', label: 'હું કોઈ એક સેવાકાર્યમાં મારા સમયનો નિયમિત સહભાગ આપીશ.' },
];

export default function SankalpRegistrationForm() {
  const { t } = useLocales();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const feedbackIds = searchParams.get('feedbackId');

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload = {
        feedbackId: parseInt(feedbackIds, 10),
        sankalp: data.sankalpSelections,
      };

      await createSankalp(payload);

      enqueueSnackbar('તમારો સંકલ્પ પત્ર નોંધવામાં આવ્યો છે. સંકલ્પ પત્ર ભરવા બદલ તમારો આભાર!', {autoHideDuration: 6000});
      if (location.pathname !== paths.auth.jwt.register) {
        setTimeout(() => {
          window.location.href = 'https://hssfgujarat.co.in/spiritual/downloads.html';
        }, 2000);
      }
      reset();
    } catch (error) {
      console.error('Error: ', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    console.log('FeedbackId from URL:', feedbackIds);
  }, [feedbackIds]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Typography variant="h3" gutterBottom>
        હું સંકલ્પ કરૂં છું કે, નીચે જણાવેલા કાર્યોમાંથી પસંદ કરેલા કાર્ય કરીશ તથા વિશ્વ કલ્યાણની હિંદુ સંસ્કૃતિની ભાવનાનો પ્રસાર કરીશ.
      </Typography>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <RHFMultiCustomCheckbox
            column
            name="sankalpSelections"
            options={sankalpOptions}
          />
        </Grid>

        <Grid xs={12} textAlign="end" display="flex" justifyContent="flex-end" gap={2}>
          <LoadingButton
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('submit')}
          </LoadingButton>
          <Button
            id="cancel-button"
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => {
              methods.reset();
               if (location.pathname !== paths.auth.jwt.register) {
                  window.location.href = 'https://hssfgujarat.co.in/spiritual/downloads.html';  
                }
            }}
          >
            {t('cancel')}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import FoodManagementMain from 'src/pages/HSSF/food-management/food-management-main';

import { paths } from '../paths';

export const hssfFoodManagementRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.foodManagement,
        element: <FoodManagementMain />,
      },
    ],
  },
];

import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import PreviewDataMain from 'src/pages/HSSF/preview-data/preview-data-main';

import { paths } from '../paths';

export const hssfPreviewDataRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.previewData,
        element: <PreviewDataMain />,
      },
    ],
  },
];

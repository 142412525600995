import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import FormProvider, {
  RHFTextField,
  RHFRadioGroup,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { createRangoliSpardha } from 'src/api/services/rangoli-spardha.services';  
import * as Yup from 'yup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Iconify from 'src/components/iconify';

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  ayuGat: Yup.string().required('Please select your age group'),
  phone: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter a valid Mobile Number.')
    .required('Phone number is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .nullable(),
});

const defaultValues = {
  name: '',
  ayuGat: '',
  phone: '',
  email: '',
};

const ayuGatOptions = [
  { value: '15-25', label: '15-25' },
  { value: '25+', label: '25+ (Only female)' },
];

export default function RangoliSpardhaRegistrationForm( {hideCancel = false} ) {
  const navigate = useNavigate();
  const router = useRouter();
  const location = useLocation();
  const { t } = useLocales();

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload = {
        name: data.name,
        ayuGat: data.ayuGat,
        phone: data.phone,
        email: data.email,
      };

      await createRangoliSpardha(payload);

      if (location.pathname !== paths.auth.jwt.register) {
        router.push(paths.hssfPaths.rangoliSpardha.management.root);
      }
      enqueueSnackbar('Registration Successfully');
      reset();
    } catch (error) {
      console.error('Error: ', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      }
    }
  });


  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Accordion>
        <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
          <Typography variant="h3">રંગોળી સ્પર્ધાના નિયમો</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>(રજિસ્ટ્રેશન માટે કોઈ શુલ્ક નથી.)</p>
            <ol>
              <li>
                <strong>આયોજકો દ્વારા પ્રદાન કરેલ સામગ્રી:</strong>
                <ul>
                  <li>એક મીટર બાય એક મીટરની MDF શીટ</li>
                  <li>રંગોળીના રંગો</li>
                  <li>ચોક</li>
                </ul>
                <p><strong>નોંધ:</strong> પેઇન્ટિંગ અને ફૂલોની મંજૂરી નથી.</p>
              </li>
              <li>
                <strong>શ્રેણીઓ:</strong>
                <ul>
                  <li>કુલ 2 શ્રેણીઓ છે, દરેક શ્રેણીમાં વધુમાં વધુ 50 સહભાગી હશે.</li>
                  <li>
                    <strong>A. યુવા (15-25 વર્ષ):</strong>
                    <ul>
                      <li>તારીખ: <strong>23 જાન્યુઆરી, 2025</strong> (મેળાનો પ્રથમ દિવસ)</li>
                      <li>સમય: <strong>14:00 થી 16:00</strong></li>
                    </ul>
                  </li>
                  <li>
                    <strong>B. ફક્ત મહિલા (25+ વર્ષ):</strong>
                    <ul>
                      <li>તારીખ: <strong>25 જાન્યુઆરી, 2025</strong> (મેળાનો ત્રીજો દિવસ)</li>
                      <li>સમય: <strong>12:00 થી 14:00</strong></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <strong>વિષય:</strong> (નીચેના 3માંથી કોઈપણ 1 થીમ પસંદ કરો)
                <ul>
                  <li>હિન્દુ જીવનશૈલી</li>
                  <li>પ્રકૃતિ + વન્યજીવન</li>
                  <li>મારો પરિવાર</li>
                </ul>
              </li>
              <li>
                <strong>નિર્ણાયકોની ફાઈનલ ભલામણ:</strong>
                <p>નિર્ણાયકોના નિર્ણયો અંતિમ રહેશે.</p>
                <p><strong>મુલ્યાંકન માપદંડ:</strong></p>
                <ul>
                  <li>વિચારની સ્પષ્ટતા</li>
                  <li>કલર કોમ્બિનેશન</li>
                  <li>સર્જનાત્મકતા</li>
                </ul>
              </li>
              <li>
                <strong>ઈનામો:</strong> દરેક શ્રેણી માટે 3 પુરસ્કારો.
              </li>
              <li>
                <strong>નોંધણી:</strong> આપેલ લિંક પર વહેલા તે પહેલાના ધોરણે નોંધણી કરાવવી.
              </li>
            </ol>
            <p><strong>સ્થાન:</strong> ગુજરાત યુનિવર્સિટી મેદાન, અમદાવાદ</p>
            <p><strong>સંપર્ક:</strong> શિલ્પેશ: 9824514138</p>
            <h3><strong>Rangoli Competition Rules</strong></h3>
            <p>(No registration fees.)</p>
            <ol>
              <li>
                <strong>Materials Provided by Organizers:</strong>
                <ul>
                  <li>1 meter by 1 meter MDF sheet</li>
                  <li>Rangoli colors</li>
                  <li>Chalk</li>
                </ul>
                <p><strong>Note:</strong> Painting and flowers are not allowed.</p>
              </li>
              <li>
                <strong>Categories:</strong>
                <ul>
                  <li>There are 2 categories, with a maximum of 50 participants in each.</li>
                  <li>
                    <strong>A. Youth (15-25 years):</strong>
                    <ul>
                      <li>Date: <strong>23rd January, 2025</strong> (First day of the fair)</li>
                      <li>Time: <strong>2:00 PM to 4:00 PM</strong></li>
                    </ul>
                  </li>
                  <li>
                    <strong>B. Ladies (25+ years):</strong>
                    <ul>
                      <li>Date: <strong>25th January, 2025</strong> (Third day of the fair)</li>
                      <li>Time: <strong>12:00 PM to 2:00 PM</strong></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Theme:</strong> (Choose any 1 from the 3 options below)
                <ul>
                  <li>Hindu Lifestyle</li>
                  <li>Nature + Wildlife</li>
                  <li>My Family</li>
                </ul>
              </li>
              <li>
                <strong>Judges Final Decision:</strong>
                <p>The decision of the judges will be final.</p>
                <p><strong>Evaluation Criteria:</strong></p>
                <ul>
                  <li>Clarity of Idea</li>
                  <li>Color Combination</li>
                  <li>Creativity</li>
                </ul>
              </li>
              <li>
                <strong>Rewards:</strong> 3 prizes for each category.
              </li>
              <li>
                <strong>Registration:</strong> First-come, first-served basis through the provided link.
              </li>
            </ol>
            <p><strong>Location:</strong> Gujarat University Ground, Ahmedabad</p>
            <p><strong>Contact:</strong> Shilpesh: 9824514138</p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <RHFTextField
            name="name"
            label="Name *"
            fullWidth
          />
        </Grid>

        <Grid xs={12}>
          <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
            Age Group *
          </Typography>
          <RHFRadioGroup
            name="ayuGat"
            options={ayuGatOptions}
            sx={{ ml: 1 }}
          />
        </Grid>

        <Grid xs={12}>
          <RHFTextField
            name="phone"
            label="Phone *"
            type="number"
            inputProps={{ maxLength: 10 }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 10);
            }}
            fullWidth
          />
        </Grid>

        <Grid xs={12}>
          <RHFTextField
            name="email"
            label="Email (Optional)"
            fullWidth
          />
        </Grid>

        <Grid xs={12} textAlign="end" display="flex" justifyContent="flex-end" gap={2}>
          <LoadingButton
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('register')}
          </LoadingButton>
          {!hideCancel && <Button
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => {
              methods.reset();
              navigate(paths.hssfPaths.rangoliSpardha.management.root);
            }}
          >
            {t('cancel')}
          </Button>}
        </Grid>
      </Grid>
    </FormProvider>
  );
}

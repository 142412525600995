import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import { getFairYear, ROLES } from 'src/utils/utils';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  addUser: icon('ic_add_user'),
  shanshtha: icon('ic_shanshtha'),
  dashboard_2: icon('ic_dashboard_2'),
  event: icon('ic_event'),
  event_cal: icon('ic_event_cal'),
  ic_fair: icon('ic_fair'),
  ic_team_management: icon('ic_team_management'),
  ic_institution: icon('ic_institution'),
  ic_report: icon('ic_report'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // ---------------------------------------------------------------------------------- HSSF-START
      {
        subheader: 'HSSF',
        items: [
          // Navigation: Dashboard
          {
            title: t('dashboard'),
            path: paths.hssfPaths.root,
            icon: ICONS.dashboard,
            roles: [
              ROLES.superAdmin,
              ROLES?.prant,
              ROLES.vibhag,
              ROLES.jilla,
              ROLES.taluka,
              ROLES.karykarta,
              ROLES.admin,
            ],
          },
          // Navigation: Profile
          {
            title: t('profile'),
            path: paths.hssfPaths.profile.root,
            icon: ICONS.user,
            commonActive: 'profile',
          },
          // Navigation: Fair
          {
            title: `${t('fair')} - ${getFairYear()}`,
            path: paths.hssfPaths.fair.root,
            icon: ICONS.ic_fair,
            commonActive: 'fair',
          },
          // Navigation: Preview Data
          {
            title: t('preview_data'),
            path: paths.hssfPaths.previewData,
            icon: ICONS.ic_team_management,
            commonActive: 'preview-data',
          },
          // Navigation: Food Management
          {
            title: t('food_management'),
            path: paths.hssfPaths.foodManagement,
            icon: ICONS.ic_team_management,
            commonActive: 'food-management',
          },
          // Navigation: Team Management
          {
            title: t('team_management'),
            path: paths.hssfPaths.teamManagement.root,
            icon: ICONS.ic_team_management,
            roles: [ROLES?.superAdmin, ROLES?.admin],
          },
          // Navigation: Gujarat Prant
          {
            title: t('gujarat_prant'),
            path: paths.hssfPaths.prant.root,
            icon: ICONS.dashboard_2,
            commonActive: 'view',
            roles: [ROLES?.prant],
          },
          // Navigation: Vibhag
          {
            title: t('vibhag'),
            path: paths.hssfPaths.vibhag.root,
            icon: ICONS.dashboard_2,
            commonActive: 'view',
            roles: [ROLES.vibhag],
          },
          // Navigation: Jilla
          {
            title: t('jilla'),
            path: paths.hssfPaths.jilla.root,
            icon: ICONS.dashboard_2,
            commonActive: 'view',
            roles: [ROLES.jilla],
          },
          // Navigation: Taluka
          {
            title: t('taluka'),
            path: paths.hssfPaths.taluka.root,
            icon: ICONS.dashboard_2,
            commonActive: 'view',
            roles: [ROLES.taluka],
          },
          // Navigation: User Management
          {
            title: t('user_management'),
            path: paths.hssfPaths.user.management.root,
            icon: ICONS.user,
            roles: [ROLES.superAdmin, ROLES.admin],
            commonActive: 'user',
          },
          // Navigation: Prabandhak Management
          {
            title: t('prabandhak_management'),
            path: paths.hssfPaths.prabandhak.management.root,
            icon: ICONS.booking,
            roles: [ROLES.superAdmin, ROLES.admin],
            commonActive: 'prabandhak',
          },
          // Navigation: Kalashyatra Management
          {
            title: t('kalashyatra_management'),
            path: paths.hssfPaths.kalashyatra.management.root,
            icon: ICONS.user,
            commonActive: 'kalashyatra',
            roles: [ROLES.superAdmin, ROLES.admin],
          },
          // Navigation: Selfie Management
          {
            title: t('Selfie Management'),
            path: paths.hssfPaths.selfie.management.root,
            icon: ICONS.user,
            roles: [ROLES.superAdmin, ROLES.admin],
            commonActive: 'selfie',
          },
          // Navigation: Rangoli Spardha
          {
            title: t('rangoli_spardha'),
            path: paths.hssfPaths.rangoliSpardha.management.root,
            icon: ICONS.user,
            commonActive: 'rangoli-spardha',
            roles: [ROLES.superAdmin, ROLES.admin],
          },
          // Navigation: Sanstha Registration
          {
            title: t('sanstha_registration.sanstha_management'),
            path: paths.hssfPaths.registration.list,
            icon: ICONS.user,
            commonActive: 'registration-list',
          },
          // Navigation: Family Registration
          {
            title: t('family_registration'),
            path: paths.hssfPaths.familyRegistration.list,
            icon: ICONS.user,
            commonActive: 'family-registration',
          },
          // Navigation: Event Management
          {
            title: t('event_management'),
            path: paths.hssfPaths.event.root,
            icon: ICONS.event,
            commonActive: 'event',
          },
          // Navigation: Game Registration
          {
            title: t('game_registration.title'),
            path: paths.hssfPaths.gameRegistration.list,
            icon: ICONS.user,
            commonActive: 'game',
          },
          // Navigation: Report
          {
            title: t('dashboard_tab.report'),
            path: paths.hssfPaths.report.root,
            icon: ICONS.ic_report,
            roles: [ROLES.superAdmin, ROLES.admin],
            commonActive: 'report',
          },
          // Navigation: Feedback
          {
            title: t('feedback'),
            path: paths.hssfPaths.feedback.management.root,
            icon: ICONS.user,
            commonActive: 'feedback',
            roles: [ROLES.superAdmin, ROLES.admin],
          },

          // Navigation: Visitors Registration
          {
            title: t('visitors_management'),
            path: paths.hssfPaths.visitors.management.root,
            icon: ICONS.user,
            commonActive: 'visitors',
            roles: [ROLES.superAdmin, ROLES.admin],
          },
        ],
      },
      // ---------------------------------------------------------------------------------- HSSF-END
    ],
    [t]
  );

  return data;
}

import Container from '@mui/material/Container';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { ROLES } from 'src/utils/utils';
import { paths } from 'src/routes/paths';
import { useLocation } from 'react-router-dom';
import Footer from 'src/components/footer/footer';
import NewFamilyRegistrationForm from './new-family-registration-form';

export default function AddFamilyRegistrationView() {
  const { t } = useLocales();
  const settings = useSettingsContext();
  const user = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();
  const isPublic = location.pathname === '/hssf/family-registration-list/add';

  return (
    <>
    {isPublic && <img src="https://hssfgujarat.s3.ap-south-1.amazonaws.com/registration_header.png" alt="Registration Header" style={{ width: '100%', height: 'auto' }} />}
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      {!isPublic ? (
        <CustomBreadcrumbs
        heading={t('family_Registration.registration_title')}
        links={[
          {
            name: t('dashboard'),
            href:
              user?.user_role === ROLES.superAdmin
                ? paths.hssfPaths.root
                : paths.hssfPaths.registration.list,
          },
          {
            name: t('family_Registration.registration_title'),
            href: paths.hssfPaths.familyRegistration.list,
          },
          {
            name: t('registration'),
            href: paths.hssfPaths.familyRegistration.list,
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 }, my: 2.5 }}
      />
      ) : (
        <CustomBreadcrumbs
          heading={t('family_Registration.registration_title')}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      )}
      <NewFamilyRegistrationForm />
    </Container>
    {isPublic && <Footer/>}
    </>
  );
}

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import FormProvider, {
  RHFTextField,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { createVisitorsRegistration } from 'src/api/services/visitors.services';  
import * as Yup from 'yup';

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  mobileNo: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter a valid Mobile Number.')
    .nullable(),
  email: Yup.string()
    .email('Must be a valid email'),
  reference: Yup.string().nullable(),
});

const defaultValues = {
  name: '',
  mobileNo: '',
  email: '',
  location: '',
  reference: '',
};


export default function VisitorsRegistrationForm( {hideCancel = false} ) {
  const navigate = useNavigate();
  const router = useRouter();
  const location = useLocation();
  const { t } = useLocales();

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload = {
        name: data.name,
        mobileNo: data.mobileNo,
        email: data.email,
        location: data.location,
        reference: data.reference,
      };

      await createVisitorsRegistration(payload);

      if (location.pathname !== paths.auth.jwt.register) {
        router.push(paths.hssfPaths.visitors.management.root);
      }
      enqueueSnackbar('Registration Successfully');
      reset();
    } catch (error) {
      console.error('Error: ', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      }
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} >
          <RHFTextField
            name="name"
            label="Name *"
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid xs={12} >
          <RHFTextField
            name="email"
            label="Email Address (Optional)"
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid xs={12}>
          <RHFTextField
            name="mobileNo"
            label="Mobile No. *"
            type="number"
            inputProps={{ maxLength: 10 }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 10);
            }}
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid xs={12}>  
          <RHFTextField
            name="location"
            label="Location (Optional)"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid xs={12}>
          <RHFTextField
            name="reference"
            label="Reference (Optional)"
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid xs={12} textAlign="end" display="flex" justifyContent="flex-end" gap={2}>
          <LoadingButton
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('register')}
          </LoadingButton>
          {!hideCancel && <Button
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => {
              methods.reset();
              navigate(paths.hssfPaths.visitors.management.root);
            }}
          >
            {t('cancel')}
          </Button>}  
        </Grid>
      </Grid>
    </FormProvider>
  );
}

import './identity.css';

function IdentityCard({
  karyaVibhagId,
  name,
  mobile,
  karyavibhag,
  adhar,
  sansthaname,
  city,
  profileImgUrl,
  qrImgUrl,
  id,
}) {
  let identity;
  if (karyaVibhagId === 68) {
    identity = 'પ્રતિનિધિ';
  } else if (karyaVibhagId === 69 || karyaVibhagId === 70) {
    identity = 'અત્તિથી | વિષેશ અત્તિથી';
  } else {
    identity = 'પ્રબંધક';
  }

  const karyavibhags = [
    { id: 68, karyavibhagName: 'સંસ્થા પ્રતિનિધિ', color: '#3D3F94' },
    { id: 76, karyavibhagName: 'રક્ષક', color: '#9B4722' },
    { id: 77, karyavibhagName: 'પત્રકાર', color: '#6B3FA0' },
    { id: 78, karyavibhagName: 'કર્મચારી', color: '#00A859' },
    { id: 69, karyavibhagName: 'અતિથિ', color: '#F0801E' },
    { id: 70, karyavibhagName: 'વિશેષ અતિથિ', color: '#D35400' },
    { id: 59, karyavibhagName: 'કોર સમિતિ', color: '#A20004' },
    { id: 63, karyavibhagName: 'શ્રેષ્ઠી સમિતિ', color: '#B8860B' },
    { id: 62, karyavibhagName: 'સ્વાગત સમિતિ', color: '#8B0C70' },
    { id: 61, karyavibhagName: 'માર્ગદર્શક મંડળ', color: '#654321' },
    { id: 79, karyavibhagName: 'કાર્યવિભાગ સંયોજક', color: '#0BB7BD' },
  ];

  const currentkaryavibhag = karyavibhags.filter((vibhag) => vibhag.id === karyaVibhagId);
  return (
    <div className="main-id-container">
      <div className="card1">
        <div className="header">
          <img
            src="https://hssfgujarat.s3.ap-south-1.amazonaws.com/id_card_header.jpeg"
            alt="h3"
            className="h-img3"
            style={{ WebkitPrintColorAdjust: 'exact' }}
          />
        </div>
        <div className="ph-qr pd-block">
          {(identity === 'પ્રબંધક' || identity === 'પ્રતિનિધિ') && profileImgUrl && (
            <img
              src={profileImgUrl}
              alt="profile"
              style={{
                width: '140px',
                height: '140px',
                objectFit: 'cover',
                backgroundColor: 'gray',
                WebkitPrintColorAdjust: 'exact',
              }}
            />
          )}
          <div style={{ position: 'relative' }}>
            <img
              src={qrImgUrl}
              alt="qr"
              style={{
                width: '140px',
                height: '140px',
                WebkitPrintColorAdjust: 'exact',
              }}
            />

            {id && String(id).trim() !== '' && (
              <div style={{ 
                position: 'absolute',
                left: '100%',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                gap: '4px',
                marginLeft: '8px'
              }}>
                {String(id).split('').map((char, index) => (
                  <span key={index}>{char}</span>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="pd-block">
          <div className="details">
            <h4>નામ :-</h4>
            <h4>{name || ''}</h4>
          </div>
          <div className="details">
            <h4>મોબાઈલ નં :-</h4>
            <h4>{mobile || ''}</h4>
          </div>
          {currentkaryavibhag.length < 1 && (
            <div className="details">
              <h4>કાર્યવિભાગ :-</h4>
              <h4>{karyavibhag || ''}</h4>
            </div>
          )}
          {identity === 'પ્રતિનિધિ' && (
            <div className="details">
              <h4>આધારકાર્ડ :-</h4>
              <h4>{adhar || ''}</h4>
            </div>
          )}
          {identity === 'પ્રતિનિધિ' && (
            <div className="details">
              <h4>સંસ્થા નું નામ :-</h4>
              <h4
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  wordWrap: 'normal',
                }}
              >
                {sansthaname || ''}
              </h4>
            </div>
          )}
          {identity === 'અત્તિથી | વિષેશ અત્તિથી' && (
            <div className="details">
              <h4>શહેર :-</h4>
              <h4>{city || ''}</h4>
            </div>
          )}
        </div>
        {currentkaryavibhag.length > 0 ? (
          <h5
            className="bd"
            style={{
              backgroundColor: currentkaryavibhag[0]?.color,
              WebkitPrintColorAdjust: 'exact',
            }}
          >
            {currentkaryavibhag[0]?.karyavibhagName}
          </h5>
        ) : (
          <h5
            className="bd"
            style={{
              backgroundColor: '#52658D',
              WebkitPrintColorAdjust: 'exact',
            }}
          >
            પ્રબંધક
          </h5>
        )}
      </div>
    </div>
  );
}

export default IdentityCard;

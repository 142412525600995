import { doc, getDoc, collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { DB } from 'src/auth/context/firebase/auth-provider';

export const getDocumentById = async (collectionName, documentId) => {
  try {
    const docRef = doc(DB, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        id: docSnap.id,
        ...docSnap.data()
      };
    }
    return null;
  } catch (error) {
    console.error('Error fetching document:', error);
    throw error;
  }
};

export const getCollectionData = async (collectionName, conditions = [], orderByField = null) => {
  try {
    let q = collection(DB, collectionName);

    if (conditions.length) {
      conditions.forEach((condition) => {
        q = query(q, where(condition.field, condition.operator, condition.value));
      });
    }

    if (orderByField) {
      q = query(q, orderBy(orderByField.field, orderByField.direction || 'asc'));
    }

    const querySnapshot = await getDocs(q);
    const data = [];
    
    querySnapshot.forEach((docs) => {
      data.push({
        id: docs.id,
        ...docs.data()
      });
    });

    return data;
  } catch (error) {
    console.error('Error fetching collection data:', error);
    throw error;
  }
};
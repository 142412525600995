import React, { useRef, useEffect, useState } from 'react';
import { Grid, Button, Box, Typography } from '@mui/material';
import IdentityCard from 'src/components/identity-card/IdentityCard';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';

const CARDS_PER_ROW = 2; // 2 cards per row
const CARD_WIDTH = 86; // card width in mm
const CARDS_PER_PAGE = 50;

const formatSansthaName = (string, limit = 100) => {
  if (!string) return '';

  // Remove quotes and format the string
  const formattedString = string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
    .replace(/"/g, '');

  // Limit the string length and add ellipsis if needed
  return formattedString.length > limit
    ? `${formattedString.substring(0, limit)}...`
    : formattedString;
};

const QRCodePDFViewer = ({ users }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const targetRef = useRef(null);
  const cardRefs = useRef({});

  // Calculate pagination values
  const totalPages = Math.ceil(users.length / CARDS_PER_PAGE);
  const startIndex = (currentPage - 1) * CARDS_PER_PAGE;
  const endIndex = startIndex + CARDS_PER_PAGE;
  const currentUsers = users.slice(startIndex, endIndex);

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const waitForImageLoad = (imgElement) =>
    new Promise((resolve) => {
      if (imgElement.complete) {
        resolve();
      } else {
        imgElement.onload = () => resolve();
        imgElement.onerror = () => resolve(); // Handle error case
      }
    });

  const downloadAllCards = async () => {
    const zip = new JSZip();

    const cardPromises = currentUsers.map(async (user) => {
      const cardElement = cardRefs.current[user.id];
      if (!cardElement) return;

      try {
        // Pre-load all images with CORS headers
        const images = [...cardElement.getElementsByTagName('img')];
        const imageLoadPromises = images.map((img) => {
          const newImg = new Image();
          newImg.crossOrigin = 'anonymous';
          newImg.src = img.src.includes('?')
            ? `${img.src}&t=${new Date().getTime()}`
            : `${img.src}?t=${new Date().getTime()}`;
          return waitForImageLoad(newImg).then(() => {
            img.src = newImg.src;
          });
        });

        await Promise.all(imageLoadPromises);

        const canvas = await html2canvas(cardElement, {
          scale: 2,
          useCORS: true,
          backgroundColor: '#ffffff',
          logging: false,
          allowTaint: false,
          removeContainer: false,
          imageTimeout: 0,
          onclone: (clonedDoc) => {
            const clonedElement = clonedDoc.querySelector(`[data-id="${user.id}"]`);
            if (clonedElement) {
              clonedElement.style.visibility = 'visible';
              clonedElement.style.background = '#ffffff';
              clonedElement.style.display = 'block';
            }
          },
        });

        const blob = await new Promise((resolve) => {
          canvas.toBlob(resolve, 'image/jpeg', 1.0);
        });

        if (blob) {
          const index = currentUsers.indexOf(user) + 1;
          zip.file(`${index}.jpg`, blob);
        }
      } catch (error) {
        console.error(`Error processing card for ${user.firstName}:`, error);
      }
    });

    try {
      await Promise.all(cardPromises);
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, `ID_Cards_Page${currentPage}.zip`);
    } catch (error) {
      console.error('Error creating zip file:', error);
    }
  };

  // Add this function to handle single card download
  const downloadSingleCard = async (user) => {
    const cardElement = cardRefs.current[user.id];
    if (!cardElement) return;

    try {
      // Pre-load images with CORS headers
      const images = [...cardElement.getElementsByTagName('img')];
      const imageLoadPromises = images.map((img) => {
        const newImg = new Image();
        newImg.crossOrigin = 'anonymous';
        newImg.src = img.src.includes('?')
          ? `${img.src}&t=${new Date().getTime()}`
          : `${img.src}?t=${new Date().getTime()}`;
        return waitForImageLoad(newImg).then(() => {
          img.src = newImg.src;
        });
      });

      await Promise.all(imageLoadPromises);

      const canvas = await html2canvas(cardElement, {
        scale: 2,
        useCORS: true,
        backgroundColor: '#ffffff',
        logging: false,
        allowTaint: false,
        removeContainer: false,
        imageTimeout: 0,
        onclone: (clonedDoc) => {
          const clonedElement = clonedDoc.querySelector(`[data-id="${user.id}"]`);
          if (clonedElement) {
            clonedElement.style.visibility = 'visible';
            clonedElement.style.background = '#ffffff';
            clonedElement.style.display = 'block';
          }
        },
      });

      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/jpeg', 1.0);
      });

      if (blob) {
        saveAs(blob, `ID_Card_${user.firstName}_${user.lastName}.jpg`);
      }
    } catch (error) {
      console.error(`Error processing card for ${user.firstName}:`, error);
    }
  };

  // Add print-specific styles when component mounts
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @media print {
        body * {
          visibility: hidden;
        }
        #print-section,
        #print-section * {
          visibility: visible;
        }
        #print-section {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }
        .no-print {
          display: none !important;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button variant="contained" color="primary" onClick={() => downloadAllCards(currentUsers)}>
          Download Current Page ID Cards
        </Button>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Button variant="outlined" onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </Button>
          <Typography>
            Page {currentPage} of {totalPages}
          </Typography>
          <Button variant="outlined" onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </Button>
        </Box>
      </Box>

      <div ref={targetRef} id="print-section">
        <Grid
          container
          sx={{
            width: `${CARD_WIDTH * CARDS_PER_ROW + 2}mm`,
            margin: '0 auto',
            display: 'grid',
            gridTemplateColumns: `repeat(${CARDS_PER_ROW}, ${CARD_WIDTH}mm)`,
            gap: '1mm',
            justifyContent: 'center',
            '& .MuiGrid-item': {
              width: `${CARD_WIDTH}mm`,
              padding: 0,
              minWidth: `${CARD_WIDTH}mm`,
              maxWidth: `${CARD_WIDTH}mm`,
            },
            '@media print': {
              width: '100%',
              gap: '0.5mm',
              pageBreakInside: 'avoid',
              pageBreakAfter: 'always',
            },
          }}
        >
          {currentUsers.map((user) => (
            <Grid
              item
              key={user.id}
              sx={{
                '@media print': {
                  pageBreakInside: 'avoid',
                },
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <div
                  ref={(el) => {
                    cardRefs.current[user.id] = el;
                  }}
                  data-id={user.id}
                  style={{ background: '#ffffff' }}
                >
                  <IdentityCard
                    karyaVibhagId={user.karyaVibhagId}
                    name={`${user.firstName} ${user.lastName}`}
                    mobile={user.mobileNo}
                    karyavibhag={user.karyaVibhag.name}
                    adhar={user.aadharNumber}
                    sansthaname={formatSansthaName(user.address)}
                    city={user.city}
                    profileImgUrl={user.userImage}
                    qrImgUrl={user.qrCode}
                  />
                </div>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => downloadSingleCard(user)}
                  sx={{
                    position: 'absolute',
                    bottom: '8px',
                    right: '8px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                      color: 'white',
                    },
                  }}
                >
                  Download
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default QRCodePDFViewer;

import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import FormHelperText from '@mui/material/FormHelperText';
//
import { Upload, UploadBox } from '../upload';

// ----------------------------------------------------------------------

export function RHFUploadVideoBox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox files={field.value} error={!!error} {...other} />
      )}
    />
  );
}

RHFUploadVideoBox.propTypes = {
  name: PropTypes.string,
};

// ----------------------------------------------------------------------

// export function RHFUploadVideo({ name, multiple, helperText, ...other }) {
//   const { control } = useFormContext();

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field, fieldState: { error } }) =>
//         multiple ? (
//           <Upload
//             multiple
//             accept={{ 'video/*': [] }}
//             files={field.value}
//             error={!!error}
//             helperText={
//               (!!error || helperText) && (
//                 <FormHelperText error={!!error} sx={{ px: 2 }}>
//                   {error ? error?.message : helperText}
//                 </FormHelperText>
//               )
//             }
//             {...other}
//           />
//         ) : (
//           <Upload
//             accept={{ 'video/*': [] }}
//             file={field.value}
//             error={!!error}
//             helperText={
//               (!!error || helperText) && (
//                 <FormHelperText error={!!error} sx={{ px: 2 }}>
//                   {error ? error?.message : helperText}
//                 </FormHelperText>
//               )
//             }
//             {...other}
//           />
//         )
//       }
//     />
//   );
// }

// RHFUploadVideo.propTypes = {
//   helperText: PropTypes.string,
//   multiple: PropTypes.bool,
//   name: PropTypes.string,
// };

export function RHFUploadVideo({ name, multiple, helperText, maxSize, ...other }) {
  const { control } = useFormContext();

  const handleFileChange = (files) => {
    if (files && files[0]) {
      // Convert maxSize to bytes and check if the file exceeds the size limit
      const sizeLimit = maxSize * 1024 * 1024; // Convert MB to bytes
      const isFileTooLarge = files[0].size > sizeLimit;
      if (isFileTooLarge) {
        alert(`File should not be greater than ${maxSize} MB`);
        // Prevent file from being set if too large
      }
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        multiple ? (
          <Upload
            multiple
            accept={{ 'video/*': [] }}
            files={field.value}
            onChange={(e) => {
              handleFileChange(e.target.files);
              field.onChange(e);
            }}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        ) : (
          <Upload
            accept={{ 'video/*': [] }}
            file={field.value}
            onChange={(e) => {
              handleFileChange(e.target.files);
              field.onChange(e);
            }}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        )
      }
    />
  );
}

RHFUploadVideo.propTypes = {
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  maxSize: PropTypes.number, // maxSize is now a required prop
};

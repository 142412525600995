import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import VisitorsRegistrationMain from 'src/pages/HSSF/visitors/visitors-registration/visitors-registration-main';

import { paths } from '../paths';

export const hssfVisitorsRegistrationRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.visitorsRegistration,
        element: <VisitorsRegistrationMain />,
      },
    ],
  },
];

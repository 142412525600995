import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/hooks';
import { getKaryaVibhags } from 'src/api/teams';
import FormProvider, {
  RHFAutocomplete,
  RHFCheckbox,
  RHFTextField,
  RHFUploadAvatar,
  RHFRadioGroup,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import { fData } from 'src/utils/format-number';
import { dropDownLabelValue_, ROLES } from 'src/utils/utils';
import * as Yup from 'yup';

export const Reference_registration_person = [
  {
    person_name: 'Mukesh vasani',
    guj_person_name: 'મુકેશ વસાણી',
    address: 'Ahmadabad',
    guj_address: 'અમદાવાદ',
    value: 'mukesh_vasani',
    contact: 9876543210,
  },

  {
    person_name: 'Himat bhalgama',
    guj_person_name: 'હિમત ભલગામા',
    address: 'Rajkot',
    guj_address: 'રાજકોટ',
    value: 'himat_bhalgama',
    contact: 76456789230,
  },
];

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required('First name required'),
  lastName: Yup.string().required('Last name required'),
  contact: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter a valid Mobile Number.')
    .required('Enter Mobile Number')
    .typeError('Please enter a valid Mobile Number.'),
  whatsapp_number: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter a valid Whatsapp Number.')
    .required('Enter Whatsapp Number')
    .typeError('Please enter a valid Whatsapp Number.'),
  address: Yup.string().required('Address is required'),
  karyaVibag: Yup.object().required('Karya Vibag is required'),
  gender: Yup.string().required('Gender is required'),
});

const defaultValues = {
  firstName: '',
  lastName: '',
  same_as_whatsapp_number: false,
  contact: '',
  whatsapp_number: '',
  address: '',
  pin_code: '',
  aadhar_number: '',
  karyaVibag: null,
  gender: 'male',
};

export default function PrabandhakRegistrationForm({ hideCancel = false }) {
  const { register, authenticated } = useAuthContext();
  const navigate = useNavigate();
  // const { authenticated } = useAuthContext();

  const location = useLocation();
  const aadharImageRef = useRef(null);

  const [karyaVibhagOptions, setKaryaVibhagOptions] = useState([]);
  const [karyaVibhagData, setKaryaVibhag] = useState([]);

  // Add this to get karyaVibhagId from URL
  const searchParams = new URLSearchParams(location.search);
  const karyaVibhagId = searchParams.get('karyaVibhagId');

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const uploadFile = async (file) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}api/upload`,
          formData
        );
        return data;
      }
      return null;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  // ------------------------------------------------------------------------------------------------------------------------------ language-local HSSF-START
  const { t } = useLocales();
  // ------------------------------------------------------------------------------------------------------------------------------ language-local HSSF-START

  useEffect(() => {
    getKaryaVibhags()
      .then((res) => {
        setKaryaVibhag(res);
        const options = dropDownLabelValue_(res);
        setKaryaVibhagOptions(options);

        // Set default karyaVibhag if karyaVibhagId exists
        if (karyaVibhagId) {
          const defaultKaryaVibhag = options.find(
            (option) => option.value === parseInt(karyaVibhagId, 10)
          );
          if (defaultKaryaVibhag) {
            setValue('karyaVibag', defaultKaryaVibhag);
          }
        }
      })
      .catch((err) => console.error('error', err));
  }, [karyaVibhagId, setValue]);

  // Determine if special case (karyaVibhagId === '69' or '70')
  const isSpecialCase = karyaVibhagId === '69' || karyaVibhagId === '70';

  // for set default value set of contact number into whatsapp number
  useEffect(() => {
    if (values.same_as_whatsapp_number) {
      setValue('whatsapp_number', values?.contact);
    }
  }, [setValue, values?.contact, values.same_as_whatsapp_number]);

  const onSubmit = handleSubmit(async (data) => {
    // Validate pin_code and aadhar_number selection if not a special case
    if (!isSpecialCase) {
      if (!data.pin_code || !/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(data.pin_code)) {
        enqueueSnackbar('Please enter a valid pincode.', { variant: 'warning' });
        return;
      }

      if (!data.aadhar_number || !/^[0-9]{12}$/.test(data.aadhar_number)) {
        enqueueSnackbar('Aadhar number should be 12 digits and contain only numbers.', {
          variant: 'warning',
        });
        return;
      }
    }

    const response = await uploadFile(data?.user_image);
    const responseAadhar = await uploadFile(data?.aadhar_image);
    const samuhIds = karyaVibhagData?.find(
      (karyaVibhag) => karyaVibhag.name === data.karyaVibag.label
    )?.samuhId;
    const exEmail = `user${Math.floor(Math.random() * 10000)}@example.com`;
    const payload = {
      email: exEmail,
      username: exEmail,
      userImage: response?.data,
      password: 'User@123',
      firstName: data?.firstName,
      lastName: data?.lastName,
      mobileNo: data?.contact,
      whatsappNo: data?.whatsapp_number,
      profession: 'Prabandhak',
      address: data?.address,
      pinCode: data?.pin_code || '434343',
      talukaName: 'Ahmadabad',
      jillaName: 'Ahmadabad',
      districtId: 13,
      talukaId: 91,
      user_role: ROLES.karykarta,
      user_role_name: ROLES.karykarta,
      typesOfActivities: '',
      otherTypesOfActivities: data?.any_other_option_aayam_check
        ? data?.any_other_option_aayam_dropdown.value
        : null,
      interest: data?.interest?.join(','),
      workingPreference: 'none',
      workingHrs: '0',
      anyOtherResponsibility: data?.responsible_for_fair ? 'Yes' : 'No',
      responsible_for_fair: data.responsible_for_fair,
      aadhar_number: data?.aadhar_number.toString(),
      aadhar_image: responseAadhar?.data,
      karyaVibhag: data?.karyaVibag.label,
      karyaVibhagId: data?.karyaVibag.value,
      samuhId: samuhIds,
      gender: data?.gender,
    };

    try {
      await register?.({
        payload,
        from: location.pathname === paths.auth.jwt.register && 'user-registration',
      });

      enqueueSnackbar(
        'તમારા રજીસ્ટ્રેશન માટેની પ્રક્રિયા સફળતાપૂર્વક પૂર્ણ થઈ છે. મેળામાં પ્રબંધક બનવા બદલ તમારો આભાર!',
        {
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          persist: true, // This will make the snackbar persist until manually closed
        }
      );
      reset();
      if (location.pathname !== paths.auth.jwt.register) {
        setTimeout(() => {
          window.location.href = 'https://hssfgujarat.co.in/spiritual/downloads.html';
        }, 6000); // Redirect after snackbar duration
      }
      if (aadharImageRef.current) {
        aadharImageRef.current.value = '';
      }
    } catch (error) {
      console.error('Error: ', error);
      if (aadharImageRef.current) {
        aadharImageRef.current.value = '';
      }
      if (axios.isAxiosError(error)) {
        const err = error.response?.data?.errors;
        const err1 = err != null && err.length > 0 ? err[0].msg : error.message;
        const errorMessage = error.response?.data?.message || err1 || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
        // getErrorMg({ error: { message: errorMessage } }, { variant: 'error' });
      } else {
        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
        // getErrorMg('An unexpected error occurred', { variant: 'error' });
      }
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('user_image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const renderContactNumber = (
    <>
      <Divider sx={{ borderStyle: 'dashed', mt: 2.5, mb: 1.5 }} />

      <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
        {t('contact_details')} *
      </Typography>

      <Box
        rowGap={2.5}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <RHFTextField name="contact" label={`${t('mobile_number')} *`} />
        <RHFTextField
          disabled={values?.same_as_whatsapp_number}
          name="whatsapp_number"
          label={`${t('user_registration_page.input_field.whatsapp_number')} *`}
        />
      </Box>

      <RHFCheckbox
        name="same_as_whatsapp_number"
        label={t('user_registration_page.input_field.mobile_same_as_whatsapp')}
      />
    </>
  );
  const renderAvatarPhoto = (
    <Grid xs={12} md={4}>
      <RHFUploadAvatar
        isSquare
        name="user_image"
        maxSize={5000000}
        onDrop={handleDrop}
        helperText={
          <Typography
            variant="caption"
            sx={{
              mx: 'auto',
              display: 'block',
              textAlign: 'center',
              color: 'text.disabled',
            }}
          >
            Allowed *.jpeg, *.jpg, *.png, *.gif
            <br /> max size of {fData(5000000)}
          </Typography>
        }
      />
    </Grid>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit} sx={{ pb: '80px' }}>
      <Grid container spacing={2.5}>
        {renderAvatarPhoto}
        <Grid xs={12} md={8} pb={0}>
          <Box
            rowGap={2.5}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="firstName"
              label={`${t('user_registration_page.input_field.first_name')} *`}
            />
            <RHFTextField
              name="lastName"
              label={`${t('user_registration_page.input_field.last_name')} *`}
            />
          </Box>
          {renderContactNumber}
        </Grid>

        <Grid xs={12}>
          <Divider sx={{ borderStyle: 'dashed', mb: 1.5 }} />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFAutocomplete
            name="karyaVibag"
            label={`${t('karyaVibhag')} *`}
            options={karyaVibhagOptions}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disabled={!!karyaVibhagId} // Disable if karyaVibhagId exists
            onChange={(e, val) => {
              setValue('karyaVibag', val);
              clearErrors('karyaVibag');
            }}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFRadioGroup
            row
            name="gender"
            options={[
              { label: 'Male', value: 'male' },
              { label: 'Female', value: 'female' },
              { label: 'Other', value: 'other' },
            ]}
            label={`${t('user_registration_page.input_field.gender')} *`}
            defaultValue="male"
          />
        </Grid>

        <Grid xs={12} md={6}>
          <RHFTextField
            name="address"
            label={
              isSpecialCase ? 'Reference *' : `${t('user_registration_page.input_field.address')} *`
            }
          />
        </Grid>

        {/* Conditionally render pin_code and aadhar_number fields */}
        {!isSpecialCase && (
          <>
            <Grid xs={12} md={6}>
              <RHFTextField
                name="pin_code"
                label={`${t('user_registration_page.input_field.pin_code')} *`}
              />
            </Grid>

            <Grid xs={12} md={6}>
              <RHFTextField
                name="aadhar_number"
                type="number"
                autoComplete="off"
                label={`${t('user_registration_page.input_field.aadhar_number')} *`}
              />
            </Grid>
          </>
        )}

        <Grid xs={12} textAlign="end" display="flex" justifyContent="flex-end" gap={2}>
          <LoadingButton
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {authenticated ? t('create_user') : t('register')}
          </LoadingButton>
          {!hideCancel && (
            <Grid>
              <Button
                sx={{ maxWidth: 'fit-content' }}
                color="inherit"
                size="large"
                variant="outlined"
                onClick={() => {
                  // Reset form and clear registrationData
                  methods.reset();
                  navigate(paths.hssfPaths.prabandhak.management.root);
                }}
              >
                {t('cancel')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
}

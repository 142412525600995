import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/footer/footer';
import VisitorsRegistrationForm from 'src/sections/HSSF/visitors/visitors-registration/visitors-registration-form';

const isPublic = true;
const VisitorsRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : VISITORS REGISTRATION</title>
    </Helmet>

    
    <div style={{ padding: '20px' }}>
      <img src="https://hssfgujarat.s3.ap-south-1.amazonaws.com/registration_header.png" alt="Registration Header" style={{ width: '100%', height: 'auto' }} />
      <h1>Visitor Registration</h1>
      <VisitorsRegistrationForm hideCancel={isPublic} />
      <Footer/>
    </div>
  </>
);

export default VisitorsRegistrationMain;

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// import { useRouter } from 'src/routes/hooks';

export default function Developer() {
  useEffect(() => {
    window.location = 'developer.html';
  }, []);

  return (
    <Helmet>
      <title> Developer</title>
    </Helmet>
  );
}

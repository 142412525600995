import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';
import { ROLES } from 'src/utils/utils';
import { RoleBasedGuard } from 'src/auth/guard';

import QRCodePage from 'src/pages/HSSF/id-card/page/qr-codes';
import GeneralQRCodePage from 'src/pages/HSSF/id-card/page/general-qr-codes';
import { paths } from '../paths';

export const hssfIdCardsRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.idCards,
        element: (
          <RoleBasedGuard
            hasContent
            roles={[
              ROLES.superAdmin,
              ROLES.admin,
            ]}
          >
              <QRCodePage />
          </RoleBasedGuard>
        ),
      },
      {
        path: paths.hssfPaths.generalQRCode,
        element: (
          <RoleBasedGuard
            hasContent
            roles={[
              ROLES.superAdmin,
              ROLES.admin,
            ]}
          >
              <GeneralQRCodePage />
          </RoleBasedGuard>
        ),
      },
    ],
  },
];

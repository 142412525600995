import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import SelfieRegistrationMain from 'src/pages/HSSF/selfie/selfie-registration/selfie-registration-main';
import { paths } from '../paths';

const hideCancel = true;

export const hssfSelfieRegistrationRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.selfieRegistration,
        element: <SelfieRegistrationMain hideCancel={hideCancel} />,
      },
    ],
  },
];

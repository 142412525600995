import React, { useRef, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import IdentityCard from 'src/components/identity-card/IdentityCard';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { generateGeneralQRCodes, getAllGeneralQRs } from 'src/api/services/generalqr.services';
import { getKaryaVibhags } from 'src/api/teams';

const CARDS_PER_ROW = 2; // 2 cards per row
const CARD_WIDTH = 86; // card width in mm
const CARDS_PER_PAGE = 50;

const GenerateGeneralQR = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [karyaVibhag, setKaryaVibhag] = useState('');
  const [numEntries, setNumEntries] = useState('');
  const [generatedUsers, setGeneratedUsers] = useState([]);
  const [karyavibhagOptions, setKaryavibhagOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const targetRef = useRef(null);
  const cardRefs = useRef({});

  const fetchData = async (fetchFunction, setStateFunction, errorMessage) => {
    try {
      const response = await fetchFunction();
      const formattedData = response?.map((item) => ({
        value: item.id,
        label: item.name,
        guj_label: item.name,
      }));
      setStateFunction(formattedData);
    } catch (error) {
      console.error(`Error fetching ${errorMessage} data:`, error);
    }
  };
  const fetchInitialData = async () => {
    try {
      const response = await getAllGeneralQRs();
      setGeneratedUsers(response.data);
    } catch (error) {
      console.error('Error fetching initial QR codes:', error);
    }
  };

  useEffect(() => {
    fetchData(getKaryaVibhags, setKaryavibhagOptions, 'karyavibhag');
    fetchInitialData();
  }, []);

  // Calculate pagination values
  const totalPages = Math.ceil(generatedUsers.length / CARDS_PER_PAGE);
  const startIndex = (currentPage - 1) * CARDS_PER_PAGE;
  const endIndex = startIndex + CARDS_PER_PAGE;
  const currentUsers = generatedUsers.slice(startIndex, endIndex);

  const handleGenerateQR = async () => {
    setLoading(true);
    try {
      const response = await generateGeneralQRCodes({
        karyaVibhagId: karyaVibhag,
        numberOfEntries: parseInt(numEntries, 10),
      });
      if (response.success) {
        await fetchInitialData();
      }
    } catch (error) {
      console.error('Error generating QR codes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const waitForImageLoad = (imgElement) =>
    new Promise((resolve) => {
      if (imgElement.complete) {
        resolve();
      } else {
        imgElement.onload = () => resolve();
        imgElement.onerror = () => resolve(); // Handle error case
      }
    });

  const downloadAllCards = async () => {
    const zip = new JSZip();

    const cardPromises = currentUsers.map(async (user) => {
      const cardElement = cardRefs.current[user.id];
      if (!cardElement) return;

      try {
        // Pre-load all images with CORS headers
        const images = [...cardElement.getElementsByTagName('img')];
        const imageLoadPromises = images.map((img) => {
          const newImg = new Image();
          newImg.crossOrigin = 'anonymous';
          newImg.src = img.src.includes('?')
            ? `${img.src}&t=${new Date().getTime()}`
            : `${img.src}?t=${new Date().getTime()}`;
          return waitForImageLoad(newImg).then(() => {
            img.src = newImg.src;
          });
        });

        await Promise.all(imageLoadPromises);

        const canvas = await html2canvas(cardElement, {
          scale: 2,
          useCORS: true,
          backgroundColor: '#ffffff',
          logging: false,
          allowTaint: false,
          removeContainer: false,
          imageTimeout: 0,
          onclone: (clonedDoc) => {
            const clonedElement = clonedDoc.querySelector(`[data-id="${user.id}"]`);
            if (clonedElement) {
              clonedElement.style.visibility = 'visible';
              clonedElement.style.background = '#ffffff';
              clonedElement.style.display = 'block';
            }
          },
        });

        const blob = await new Promise((resolve) => {
          canvas.toBlob(resolve, 'image/jpeg', 1.0);
        });

        if (blob) {
          const index = currentUsers.indexOf(user) + 1;
          zip.file(`${index}.jpg`, blob);
        }
      } catch (error) {
        console.error(`Error processing card for ${user.id}:`, error);
      }
    });

    try {
      await Promise.all(cardPromises);
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, `ID_Cards_Page${currentPage}.zip`);
    } catch (error) {
      console.error('Error creating zip file:', error);
    }
  };

  const downloadSingleCard = async (user) => {
    const cardElement = cardRefs.current[user.id];
    if (!cardElement) return;

    try {
      // Pre-load images with CORS headers
      const images = [...cardElement.getElementsByTagName('img')];
      const imageLoadPromises = images.map((img) => {
        const newImg = new Image();
        newImg.crossOrigin = 'anonymous';
        newImg.src = img.src.includes('?')
          ? `${img.src}&t=${new Date().getTime()}`
          : `${img.src}?t=${new Date().getTime()}`;
        return waitForImageLoad(newImg).then(() => {
          img.src = newImg.src;
        });
      });

      await Promise.all(imageLoadPromises);

      const canvas = await html2canvas(cardElement, {
        scale: 2,
        useCORS: true,
        backgroundColor: '#ffffff',
        logging: false,
        allowTaint: false,
        removeContainer: false,
        imageTimeout: 0,
        onclone: (clonedDoc) => {
          const clonedElement = clonedDoc.querySelector(`[data-id="${user.id}"]`);
          if (clonedElement) {
            clonedElement.style.visibility = 'visible';
            clonedElement.style.background = '#ffffff';
            clonedElement.style.display = 'block';
          }
        },
      });

      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/jpeg', 1.0);
      });

      if (blob) {
        saveAs(blob, `ID_Card_${user.id}.jpg`);
      }
    } catch (error) {
      console.error(`Error processing card for ${user.id}:`, error);
    }
  };

  // Add print-specific styles when component mounts
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @media print {
        body * {
          visibility: hidden;
        }
        #print-section,
        #print-section * {
          visibility: visible;
        }
        #print-section {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }
        .no-print {
          display: none !important;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <>
      <Box sx={{ mb: 4, display: 'flex', gap: 2, alignItems: 'flex-end' }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Karya Vibhag</InputLabel>
          <Select
            value={karyaVibhag}
            label="Karya Vibhag"
            onChange={(e) => setKaryaVibhag(e.target.value)}
          >
            {karyavibhagOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Number of Entries"
          type="number"
          value={numEntries}
          onChange={(e) => {
            const { value } = e.target;
            if (value.length <= 3) {
              setNumEntries(value);
            }
          }}
          sx={{ width: 150 }}
          inputProps={{
            maxLength: 3,
            min: 0,
            max: 999,
          }}
        />

        <Button
          variant="contained"
          onClick={handleGenerateQR}
          disabled={!karyaVibhag || !numEntries || loading}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          {loading ? 'Generating...' : 'Generate QR Codes'}
        </Button>
      </Box>

      {generatedUsers.length > 0 && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => downloadAllCards(currentUsers)}
            >
              Download Current Page ID Cards
            </Button>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button variant="outlined" onClick={handlePrevPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <Typography>
                Page {currentPage} of {totalPages}
              </Typography>
              <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </Box>
          </Box>

          <div ref={targetRef} id="print-section">
            <Grid
              container
              sx={{
                width: `${CARD_WIDTH * CARDS_PER_ROW + 2}mm`,
                margin: '0 auto',
                display: 'grid',
                gridTemplateColumns: `repeat(${CARDS_PER_ROW}, ${CARD_WIDTH}mm)`,
                gap: '1mm',
                justifyContent: 'center',
                '& .MuiGrid-item': {
                  width: `${CARD_WIDTH}mm`,
                  padding: 0,
                  minWidth: `${CARD_WIDTH}mm`,
                  maxWidth: `${CARD_WIDTH}mm`,
                },
                '@media print': {
                  width: '100%',
                  gap: '0.5mm',
                  pageBreakInside: 'avoid',
                  pageBreakAfter: 'always',
                },
              }}
            >
              {currentUsers.map((user) => (
                <Grid
                  item
                  key={user.id}
                  sx={{
                    '@media print': {
                      pageBreakInside: 'avoid',
                    },
                  }}
                >
                  <Box sx={{ position: 'relative' }}>
                    <div
                      ref={(el) => {
                        cardRefs.current[user.id] = el;
                      }}
                      data-id={user.id}
                      style={{ background: '#ffffff' }}
                    >
                      <IdentityCard
                        id={user.id}  
                        karyaVibhagId={user.karyavibhagId}
                        name=""
                        mobile=""
                        karyavibhag={user.karyavibhag.name}
                        adhar=""
                        sansthaname=""
                        city=""
                        profileImgUrl=""
                        qrImgUrl={user.qrCode}
                      />
                    </div>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => downloadSingleCard(user)}
                      sx={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: 'primary.light',
                          color: 'white',
                        },
                      }}
                    >
                      Download
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default GenerateGeneralQR;

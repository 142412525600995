import Iconify from 'src/components/iconify';
import { Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography, TableHead, Box, Collapse,  } from '@mui/material';
import { useEffect, useState } from 'react';
import { getKaryaVibhags } from 'src/api/teams';
import { getCollectionData } from 'src/api/services/firebase.services';

export default function FoodManagement() {
  const [karyavibhagOptions, setKaryavibhagOptions] = useState([]);
  const [breakfastData, setBreakfastData] = useState([]);
  const [lunchData, setLunchData] = useState([]);
  const [dinnerData, setDinnerData] = useState([]);
  const [organizedData, setOrganizedData] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [expandedDates, setExpandedDates] = useState({});

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch all data in parallel
        const [karyavibhagResponse, breakfastResponse, lunchResponse, dinnerResponse] = await Promise.all([
          getKaryaVibhags(),
          getCollectionData('breakfast'),
          getCollectionData('lunch'),
          getCollectionData('dinner'),
        ]);

        setKaryavibhagOptions(karyavibhagResponse || []);
        setBreakfastData(breakfastResponse || []);
        setLunchData(lunchResponse || []);
        setDinnerData(dinnerResponse || []);
        setIsDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsDataLoaded(true);
      }
    };

    fetchInitialData();
  }, []);

  // Helper function to parse date string
  const parseDate = (dateString) => {
    // Assuming dateString is in format "DD-MM-YYYY"
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // month is 0-based in JavaScript
  };

  // Organize data by date
  useEffect(() => {
    const organizeDataByDate = () => {
      const organized = {};

      // Helper function to add meal data to organized object
      const addMealData = (data, mealType) => {
        data.forEach(meal => {
          const date = meal.id; // Assuming id is the date string
          if (!organized[date]) {
            organized[date] = {
              breakfast: null,
              lunch: null,
              dinner: null
            };
          }
          organized[date][mealType] = meal;
        });
      };

      // Add all meal types to organized data
      addMealData(breakfastData, 'breakfast');
      addMealData(lunchData, 'lunch');
      addMealData(dinnerData, 'dinner');

      // Sort dates
      const sortedData = Object.keys(organized)
        .sort((a, b) => {
          const dateA = parseDate(a);
          const dateB = parseDate(b);
          return dateA - dateB;
        })
        .reduce((obj, key) => {
          obj[key] = organized[key];
          return obj;
        }, {});

      setOrganizedData(sortedData);
    };

    if (breakfastData.length || lunchData.length || dinnerData.length) {
      organizeDataByDate();
    }
  }, [breakfastData, lunchData, dinnerData]);

  if (!isDataLoaded) {
    return null; // Or return a loading spinner
  }

  const renderMealStats = (meals) => {
    if (!meals) return null;

    return (
      <>
        {/* Breakfast Row */}
        <TableRow>
          <TableCell>
            <Typography variant="subtitle2">Breakfast</Typography>
          </TableCell>
          {karyavibhagOptions.map(karyavibhag => {
            const count = (meals.breakfast?.scannedIds || [])
              .filter(id => id.split('_')[1] === karyavibhag.id.toString()).length;
            return (
              <TableCell key={karyavibhag.id} align="center">
                <Typography variant="body2">{count || 0}</Typography>
              </TableCell>
            );
          })}
        </TableRow>

        {/* Lunch Row */}
        <TableRow>
          <TableCell>
            <Typography variant="subtitle2">Lunch</Typography>
          </TableCell>
          {karyavibhagOptions.map(karyavibhag => {
            const count = (meals.lunch?.scannedIds || [])
              .filter(id => id.split('_')[1] === karyavibhag.id.toString()).length;
            return (
              <TableCell key={karyavibhag.id} align="center">
                <Typography variant="body2">{count || 0}</Typography>
              </TableCell>
            );
          })}
        </TableRow>

        {/* Dinner Row */}
        <TableRow>
          <TableCell>
            <Typography variant="subtitle2">Dinner</Typography>
          </TableCell>
          {karyavibhagOptions.map(karyavibhag => {
            const count = (meals.dinner?.scannedIds || [])
              .filter(id => id.split('_')[1] === karyavibhag.id.toString()).length;
            return (
              <TableCell key={karyavibhag.id} align="center">
                <Typography variant="body2">{count || 0}</Typography>
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  };

  const handleExpandClick = (date) => {
    setExpandedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {Object.entries(organizedData).map(([date, meals]) => (
        <Card key={date} sx={{ mb: 3 }}>
          <Box 
            onClick={() => handleExpandClick(date)}
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between', 
              p: 2, 
              borderBottom: '1px solid rgba(0,0,0,0.12)',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.04)'
              }
            }}
          >
            <Typography variant="h6">
              {date}
            </Typography>
            <Iconify icon={expandedDates[date] ? "eva:arrow-up-fill" : "eva:arrow-down-fill"} />
          </Box>
          <Collapse in={expandedDates[date]} timeout="auto" unmountOnExit>
            <Box sx={{ p: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">Meal Type</Typography>
                      </TableCell>
                      {karyavibhagOptions.map((karyavibhag) => (
                        <TableCell key={karyavibhag.id} align="center">
                          <Typography variant="subtitle2">{karyavibhag.name}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderMealStats(meals)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </Card>
      ))}
    </Box>
  );
}

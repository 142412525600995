import GenerateGeneralQR from "../GenerateGeneralQR";


const GeneralQRCodePage = () => (
    <div>
      <h1>General QR Codes</h1>
      <GenerateGeneralQR />
    </div>
  );

export default GeneralQRCodePage;

import { Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography, TableHead } from '@mui/material';
import { useEffect, useState } from 'react';
import { getKaryaVibhags } from 'src/api/teams';
import { getUserData } from 'src/api/services/user.services';
import { getAllSchools } from 'src/api/services/sport-registration.services';
import { getFeedbackList } from 'src/api/services/feedback.services';
import { getDocumentById } from 'src/api/services/firebase.services';

export default function PreviewDataTable() {
  const [karyavibhagOptions, setKaryavibhagOptions] = useState([]);
  const [karyavibhagStats, setKaryavibhagStats] = useState([]);
  const [jillaStats, setJillaStats] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [feedbackCount, setFeedbackCount] = useState(0);
  const [androidDownloadsCount, setAndroidDownloadsCount] = useState(0);
  const [iosDownloadsCount, setIosDownloadsCount] = useState(0);
  // Process user stats for all karyavibhags
  useEffect(() => {
    const processKaryavibhagStats = () => {
      if (karyavibhagOptions.length > 0 && initialUsers.length > 0) {
        const stats = karyavibhagOptions.map(karyavibhag => {
          const userCount = initialUsers.filter(user => user.karyaVibhagId === karyavibhag.id).length;
          return {
            karyaVibhagName: karyavibhag.name,
            userCount,
          };
        });
        setKaryavibhagStats(stats);
      }
    };

    processKaryavibhagStats();
  }, [karyavibhagOptions, initialUsers]);

  // Process school stats for all jillas
  const processSchoolStats = (response) => {
    const { primarySchools, secondarySchools } = response.data;
    const allSchools = [...primarySchools, ...secondarySchools];
    const jillaMap = new Map();
    
    allSchools.forEach(school => {
      const jilla = school.district;
      jillaMap.set(jilla, (jillaMap.get(jilla) || 0) + 1);
    });

    const stats = Array.from(jillaMap.entries()).map(([jilla, count]) => ({
      jillaName: jilla,
      schoolCount: count,
    }));

    setJillaStats(stats);
  };

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch all data in parallel
        const [karyavibhagResponse, usersResponse, schoolsResponse, feedbackResponse, downloadsResponse] = await Promise.all([
          getKaryaVibhags(),
          getUserData({ karyaVibhag: true }),
          getAllSchools(),
          getFeedbackList(),
          getDocumentById('app_statistics', 'downloads'),
        ]);

        setKaryavibhagOptions(karyavibhagResponse || []);
        setInitialUsers(usersResponse?.users || []);
        processSchoolStats(schoolsResponse || {});
        setFeedbackCount(feedbackResponse?.data?.length || 0);
        setAndroidDownloadsCount(downloadsResponse?.androidCount || 0);
        setIosDownloadsCount(downloadsResponse?.iosCount || 0);
        // Set data loaded flag after all data is processed
        setIsDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsDataLoaded(true); // Set to true even on error to show empty tables
      }
    };

    fetchInitialData();
  }, []);

  if (!isDataLoaded) {
    return null; // Or return a loading spinner
  }

  return (
    <>
      {/* Karyavibhag Stats Table */}
      <Card sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ p: 2 }}>
          પ્રબંધક:  {initialUsers.length}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {karyavibhagStats.map((stat) => (
                  <TableCell key={stat.karyaVibhagName} align="center">
                    <Typography variant="subtitle1">{stat.karyaVibhagName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {karyavibhagStats.map((stat) => (
                  <TableCell key={stat.karyaVibhagName} align="center">
                    <Typography variant="body2">{stat.userCount}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {/* Jilla Stats Table */}
      <Card>
        <Typography variant="h6" sx={{ p: 2 }}>
          Game Registration Count by Jilla (Total Schools: {jillaStats.reduce((total, stat) => total + stat.schoolCount, 0)})
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {jillaStats.map((stat) => (
                  <TableCell key={stat.jillaName} align="center">
                    <Typography variant="subtitle1">{stat.jillaName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {jillaStats.map((stat) => (
                  <TableCell key={stat.jillaName} align="center">
                    <Typography variant="body2">{stat.schoolCount}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {/* Feedback Count */}
      <Card>
        <Typography variant="h6" sx={{ p: 2 }}>
          Feedback Count: {feedbackCount}
        </Typography>
      </Card>

      {/* Android Downloads Count */}
      <Card>
        <Typography variant="h6" sx={{ p: 2 }}>
          Android Downloads Count: {androidDownloadsCount}
        </Typography>
      </Card>

      {/* iOS Downloads Count */}
      <Card>
        <Typography variant="h6" sx={{ p: 2 }}>
          iOS Downloads Count: {iosDownloadsCount}
        </Typography>
      </Card>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import { useLocation } from 'react-router-dom';
import Footer from 'src/components/footer/footer';
import RangoliSpardhaRegistrationForm from './rangoli-spardha-registration-form';

export default function RangoliSpardhaRegistration() {
  const settings = useSettingsContext();
  const { t } = useLocales();
  const location = useLocation();
  const isPublic = location.pathname === '/hssf/rangoli-spardha-registration/add';

  return (
    <>
    {isPublic && <img src="https://hssfgujarat.s3.ap-south-1.amazonaws.com/registration_header.png" alt="Registration Header" style={{ width: '100%', height: 'auto' }} />}
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      {!isPublic ? (
        <CustomBreadcrumbs
        heading={t('rangoli_spardha_registration')}
        links={[
          { name: t('dashboard'), href: paths.hssfPaths.root },
          {
            name: t('rangoli_spardha_management'),
            href: paths.hssfPaths.rangoliSpardha.management.root,
          },
          { name: t('rangoli_spardha_registration') },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      ) : (
        <CustomBreadcrumbs
          heading={t('rangoli_spardha_registration')}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      )}

      {/* Form Page */}
      <Card sx={{ p: 4 }}>
        <RangoliSpardhaRegistrationForm hideCancel={isPublic} />
      </Card>
      </Container>
    {isPublic && <Footer/>}
    </>
  );
}

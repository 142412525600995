import React, { useState, useEffect } from 'react';
import { Stack, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { RouterLink } from 'src/routes/components';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

const DeleteUserMain = () => (
  <>
    <Helmet>
      <title> HSSF : DELETE USER</title>
    </Helmet>

    <DeleteUserPage />
  </>
);

export default DeleteUserMain;

function DeleteUserPage() {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const DeleteUserSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(DeleteUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleDeleteUser = async (data) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}api/check-user-exists`, {
        params: { email: data.email },
      });
      if (response.status === 200) {
        setDialogMessage('User will be deleted in 48 hours');
      }
    } catch (error) {
      if (error.response?.status === 400) {
        setDialogMessage('User does not exist');
      } else {
        enqueueSnackbar('An error occurred', { variant: 'error' });
      }
    } finally {
      setLoading(false);
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    if (dialogOpen) {
      setDialogMessage(dialogMessage); // Ensure dialogMessage is updated when dialog opens
    }
  }, [dialogOpen, dialogMessage]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Delete User</Typography>
      <Typography variant="body2">Enter the email address to delete the user.</Typography>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="email" label="Email address" />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting || loading}
      >
        Delete User
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleDeleteUser)}>
      {renderHead}

      {renderForm}

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link component={RouterLink} onClick={handleCloseDialog} variant="subtitle2">
            Close
          </Link>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import insta from "../../assets/images/insta.svg"
import fb from "../../assets/images/fb.svg"
import tw from "../../assets/images/twitter.svg"

const Footer = () => (
  <Box 
    component="footer" 
    sx={{
      position: 'relative',
      borderTop: '1px solid #1a1a1a',
      backgroundColor: '#1C1C1C',
      padding: '10px',
      zIndex: 1,
      color: '#fff',
      marginTop:"10px"
    }}
  >
    <Box className="container" sx={{ maxWidth: '1140px', margin: '0 auto' }}>
      <Grid container spacing={2} className="footer-info">
        {/* Left Section */}
        <Grid item xs={12} lg={7}>
          <Typography 
            component="ul" 
            sx={{
              listStyle: 'none',
              padding: 0,
              margin: 0,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: { xs: 'center', lg: 'flex-start' },
              textAlign: { xs: 'center', lg: 'start' },
            }}
          >
            <Typography
              component="li"
              sx={{ fontWeight: 300 }}
            >
              Copyright: ©{' '}
              <Typography
                component="span"
                sx={{ fontWeight: 700, marginX: 1 }}
              >
                HSSF - Hindu Spiritual and Service Foundation
              </Typography>
              All Rights Reserved.
            </Typography>
          </Typography>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} lg={5} textAlign={{ xs: 'center', lg: 'end' }}>
          <Typography>
            Follow us on social media
            <Link
              href="https://www.facebook.com/HSSFairOfficial"
              target="_blank"
              sx={{ marginX: 1, display: 'inline-block' }}
            >
              <img
                src={fb}
                alt="Facebook"
                className="telegram-svg"
                style={{ verticalAlign: 'middle' }}
              />
            </Link>
            <Link
              href="https://www.instagram.com/hssfgujarat/"
              target="_blank"
              sx={{ marginX: 1, display: 'inline-block' }}
            >
              <img
                 src={insta}
                alt="Instagram"
                className="telegram-svg"
                style={{ verticalAlign: 'middle' }}
              />
            </Link>
            <Link
              href="https://x.com/HSSFGuj"
              target="_blank"
              sx={{ marginX: 1, display: 'inline-block' }}
            >
              <img
               src={tw}
                alt="Twitter"
                className="telegram-svg"
                style={{ verticalAlign: 'middle' }}
              />
            </Link>
          </Typography>
        </Grid>

        {/* Center Section */}
        <Grid item xs={12} textAlign="center">
          <Typography sx={{ paddingTop: 2 }}>
            Developed and managed by{' '}
            <Link
              href="https://aveosoft.com/"
              target="_blank"
              sx={{
                color: '#fff',
                textDecoration: 'none',
                fontWeight: 700,
              }}
            >
              Aveosoft Pvt Ltd
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Box>
);

export default Footer;

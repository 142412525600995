import { Helmet } from 'react-helmet-async';
import PreviewDataTable from 'src/sections/HSSF/preview-data/preview-data';

const PreviewDataMain = () => (
  <>
    <Helmet>
      <title> HSSF : PREVIEW DATA</title>
    </Helmet>

    <PreviewDataTable />
  </>
);

export default PreviewDataMain;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
  RHFRadioGroup,
  RHFMultiSelect,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import * as Yup from 'yup';
import { currentUser, dropDownLabelValue_, } from 'src/utils/utils';
import { createFeedback } from 'src/api/services/feedback.services';
import { getJillaData, getTalukaData } from 'src/api/services/location.services';
import { useState, useEffect } from 'react';
import { useAuthContext } from 'src/auth/hooks';

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  mobile: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter a valid Mobile Number.')
    .required('Mobile number is required'),
  likeInFair: Yup.string().required('This field is required'),
  attendedPrograms: Yup.array().min(1, 'Please select at least one program'),
  likedExhibitions: Yup.array().min(1, 'Please select at least one exhibition'),
  likedStalls: Yup.string().required('Please enter stall names'),
  interestedVolunteer: Yup.string().required('Please select yes or no'),
  gender: Yup.string().required('Please select gender'),
});

const defaultValues = {
  name: '',
  mobile: '',
  jilla: {value: '', label: ''},
  taluka: {value: '', label: ''},
  likeInFair: '',
  attendedPrograms: [],
  likedExhibitions: [],
  likedStalls: '',
  interestedVolunteer: '',
  gender: 'male',
};

const programOptions = [
  { value: 'કળશ યાત્રા', label: 'કળશ યાત્રા' },
  { value: 'કન્યા વંદન', label: 'કન્યા વંદન' },
  { value: 'માં દિકરી સંમેલન', label: 'માં દિકરી સંમેલન' },
  { value: 'યુવા બાઇક રેલી', label: 'યુવા બાઇક રેલી' },
  { value: 'આચાર્ય વંદન', label: 'આચાર્ય વંદન' },
  { value: 'માતૃ-પિતૃ વંદન', label: 'માતૃ-પિતૃ વંદન' },
  { value: 'પરંપરાગત દેશી રમતો', label: 'પરંપરાગત દેશી રમતો' },
  { value: 'યુવા સંમેલન', label: 'યુવા સંમેલન' },
  { value: 'સાંસ્કૃતિક કાર્યક્રમ-રામસભા', label: 'સાંસ્કૃતિક કાર્યક્રમ-રામસભા' },
  { value: 'સાંસ્કૃતિક કાર્યક્રમ-હસે તે હરિને ગમે', label: 'સાંસ્કૃતિક કાર્યક્રમ-હસે તે હરિને ગમે' },
  { value: 'સાંસ્કૃતિક કાર્યક્રમ-રાષ્ટ્રભક્તિના ગીતોની સરગમ', label: 'સાંસ્કૃતિક કાર્યક્રમ-રાષ્ટ્રભક્તિના ગીતોની સરગમ' },
  
];

const exhibitionOptions = [
  { value: 'HSSF સંસ્થાના મૂળભૂત આયામ', label: 'HSSF સંસ્થાના મૂળભૂત આયામ' },
  { value: 'કુટુંબ પ્રબોધન', label: 'કુટુંબ પ્રબોધન' },
  { value: 'ગર્ભાધાન સંસ્કાર', label: 'ગર્ભાધાન સંસ્કાર' },
  { value: 'પુણ્યશ્લોક અહલ્યાબાઈ', label: 'પુણ્યશ્લોક અહલ્યાબાઈ' },
  { value: 'સશસ્ત્ર દળો - શસ્ત્રસરંજામ પ્રદર્શન', label: 'સશસ્ત્ર દળો - શસ્ત્રસરંજામ પ્રદર્શન' },
  { value: 'NCC & BSF', label: 'NCC & BSF' },
  { value: 'વિજ્ઞાન આધારિત - ઓડિયો, વિડિયો, ૩ડી એનિમેશન', label: 'વિજ્ઞાન આધારિત - ઓડિયો, વિડિયો, ૩ડી એનિમેશન' },
];

const volunteerOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];

export default function FeedbackRegistrationForm({hideCancel = false}) {
  const { authenticated } = useAuthContext();
  const user = currentUser();
  const location = useLocation();
  const navigate = useNavigate();
  const router = useRouter();
  const { t } = useLocales();
  const [jillaOptions, setJillaOptions] = useState([]);
  const [talukaOptions, setTalukaOptions] = useState([]);
  const [talukaData, setTalukaData] = useState([]);
  const [openSankalpModal, setOpenSankalpModal] = useState(false);
  const [feedbackIds, setFeedbackId] = useState(null);
  

  useEffect(() => {
    getJillaData()
      .then((res) => setJillaOptions(dropDownLabelValue_(res.data)))
      .catch((err) => console.error('error', err));
    getTalukaData()
      .then((res) => {
        setTalukaData(res.data);
        return setTalukaOptions(dropDownLabelValue_(res.data));
      })
      .catch((err) => console.error('error', err));
  }, []);

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues: {
      ...defaultValues,
      name: authenticated ? `${user?.firstName || ''}${user?.lastName || ''}` : '',
      mobile: user?.mobileNo || '',
      jilla: {value: user?.districtId || '', label: user?.districtName || ''},
      taluka: {value: user?.talukaId || '', label: user?.talukaName || ''}, 
    },
  });

  const {
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { isSubmitting },
  } = methods;

const handleCloseSankalpModal = () => {
    setOpenSankalpModal(false);
      enqueueSnackbar('તમારો અભિપ્રાય નોંધવામાં આવ્યો છે. અભિપ્રાય ભરવા બદલ તમારો આભાર!', {autoHideDuration: 6000});
      if (location.pathname !== paths.auth.jwt.register) {
        setTimeout(() => {
          window.location.href = 'https://hssfgujarat.co.in/spiritual/downloads.html';
        }, 2000);
      }
  };

  const handleConfirmSankalp = async () => {
    router.push({
      pathname: paths.hssfPaths.sankalpRegistration,
      search: `?feedbackId=${feedbackIds}`,
    });
    setOpenSankalpModal(false);
  };

   const SankalpConfirmationModal = () => (
    <Dialog open={openSankalpModal} onClose={handleCloseSankalpModal}>
      <DialogTitle>તમારો અભિપ્રાય નોંધવામાં આવ્યો છે.</DialogTitle>
      <DialogContent>
        <DialogContentText>તમારો અભિપ્રાય આપવા બદલ આભાર! </DialogContentText>
        <DialogContentText>શું તમે સંસ્થાના સંકલ્પ પત્ર ભરવા માટે આગળ વધવા માંગો છો?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseSankalpModal} color="error">ના</Button>
        <Button onClick={handleConfirmSankalp} color="success">
          હા
        </Button>
      </DialogActions>
    </Dialog>
  );

  const onSubmit = handleSubmit(async (data) => {
    try {
      // ! check if jilla and taluka are selected or not  
      if (!data.jilla || !data.jilla.label || !data.taluka || !data.taluka.label) {
        enqueueSnackbar('Please select both Jilla and Taluka', { variant: 'warning' });
        return;
      }
      const payload = {
        name: data.name,
        mobile: data.mobile,
        jilla: data.jilla.label,
        taluka: data.taluka.label,
        likeInFair: data.likeInFair,
        attendedPrograms: data.attendedPrograms,
        likedExhibitions: data.likedExhibitions,
        likedStalls: data.likedStalls,
        interestedVolunteer: data.interestedVolunteer,
        userId: user?.id,
        gender: data.gender,
      };

      // Your API call here
      const response = await createFeedback(payload);
      setFeedbackId(response?.data?.id);
      setOpenSankalpModal(true);
      reset();
    } catch (error) {
      console.error('Error: ', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      }
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <RHFTextField
            name="name"
            label="Name *"
            fullWidth
            disabled={authenticated}
          />
        </Grid>

        <Grid xs={12}>
          <RHFTextField
            name="mobile"
            label="Mobile Number *"
            fullWidth
            disabled={authenticated}
          />
        </Grid>

        <Grid xs={12}>
          <RHFAutocomplete
            name="jilla"
            label={`${t('jilla')} *`}
            options={jillaOptions}
            disabled={authenticated}
            onChange={(e, val) => {
              setValue('jilla', val);
              clearErrors('jilla');
              if (val) {
                const filteredTaluka = talukaData.filter(
                  (taluka) => taluka.districtId === val.value
                );
                setTalukaOptions(dropDownLabelValue_(filteredTaluka));
                setValue('taluka', null);
              }
            }}
          />
        </Grid>

        <Grid xs={12}>  
          <RHFAutocomplete
            name="taluka"
            label={`${t('taluka')} *`}
            options={talukaOptions}
            disabled={authenticated}
          />
        </Grid>

        <Grid xs={12}>
          <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
            Gender *
          </Typography>
          <RHFRadioGroup
          row
            name="gender"
            options={genderOptions}
            sx={{ ml: 1 }}
          />
        </Grid>

        <Grid xs={12}>
          <RHFTextField
            name="likeInFair"
            label="What do you most like in FAIR? *"
            multiline
            rows={3}
            fullWidth
          />
        </Grid>

        <Grid xs={12}>
          <RHFMultiSelect
            name="attendedPrograms"
            label="Did you attended any program? *"
            options={programOptions}
            sx={{ width: '100%' }}
          />
        </Grid>

        <Grid xs={12}>
          <RHFMultiSelect
            name="likedExhibitions"
            label="Which Exhibition Do you like most? *"
            options={exhibitionOptions}
            sx={{ width: '100%' }}
          />
        </Grid>

        <Grid xs={12}>
          <RHFTextField
            name="likedStalls"
            label="Which Stall do you like most? (Comma separated) *"
            helperText="Example: Food Stall, Book Stall, Handicraft Stall"
            fullWidth
          />
        </Grid>

        <Grid xs={12}>
          <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
            Are you interested to become volunteer in HSSF? *
          </Typography>
          <RHFRadioGroup
            name="interestedVolunteer"
            options={volunteerOptions}
            sx={{ ml: 1 }}
          />
        </Grid>

        <Grid xs={12} textAlign="end" display="flex" justifyContent="flex-end" gap={2}>
          <LoadingButton
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('submit')}
          </LoadingButton>
          {!hideCancel && <Button
            id="cancel-button"
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => {
              methods.reset();
              navigate(paths.hssfPaths.feedback.management.root);
            }}
          >
            {t('cancel')}
          </Button>}  
        </Grid>
      </Grid>
      <SankalpConfirmationModal />
    </FormProvider>
  );
}

import { useState, useEffect } from 'react';
import { getUserData } from 'src/api/services/user.services';
import QRCodePDFViewer from '../QRCodePDF';

const QRCodePage = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
  getUserData({karyaVibhag: true })
      .then((res) => {
        setUsers(res.users || []);
      })
      .catch((err) => console.error('error', err));
  }, []);

  return (
    <div>
      <h1>Prabandhak ID Cards</h1>
      <QRCodePDFViewer users={users} />
    </div>
  );
};

export default QRCodePage; 
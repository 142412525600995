import { Helmet } from 'react-helmet-async';
import SelfieRegistration from 'src/sections/HSSF/selfie/selfie-registration/selfie-registration';

const SelfieRegistrationMain = ({ hideCancel = false }) => (
  <>
    <Helmet>
      <title> HSSF : SELFIE REGISTRATION</title>
    </Helmet>

    <SelfieRegistration hideCancel={hideCancel} />
  </>
);

export default SelfieRegistrationMain;

import { Helmet } from 'react-helmet-async';
import FoodManagement from 'src/sections/HSSF/food-management/food-management';

const FoodManagementMain = () => (
  <>
    <Helmet>
      <title> HSSF : FOOD MANAGEMENT</title>
    </Helmet>

    <FoodManagement />
  </>
);

export default FoodManagementMain;

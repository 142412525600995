import { Helmet } from 'react-helmet-async';
import SankalpRegistrationForm from 'src/sections/HSSF/feedback/feedback-registration/sankalp-registration-form';
import Footer from 'src/components/footer/footer';

const SankalpRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : SANKALP REGISTRATION</title>
    </Helmet>

    <div style={{ padding: '20px' }}>
      <img src="https://hssfgujarat.s3.ap-south-1.amazonaws.com/registration_header.png" alt="Registration Header" style={{ width: '100%', height: 'auto' }} />
      <SankalpRegistrationForm />
      <Footer/>
    </div>
  </>
);

export default SankalpRegistrationMain;

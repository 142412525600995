import axios from 'axios';
import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

export const generateGeneralQRCodes = async (payload) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/general-qr`;
  const { data } = await axiosInstance.post(url, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return data;
};

export const getAllGeneralQRs = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/general-qr`;
  const { data } = await axiosInstance.get(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return data;
};
